<template>
	<v-sheet class="mt-5">
		<template  v-if="accessGranted">
			<v-alert color="secondary" class="mb-2">
				<p>Herzlich Willkommen bei InnGo.  </p>
				<p>Ihr/e Kollege/Kollegin möchte von Ihnen eine Einschätzung zur relevanten Thematik (mittels einer Mini-Umfrage) für das digitale Zielbild Ihres Krankenhauses erfassen. Bitte beantworten Sie hierzu die nachfolgende Frage/n in Bezug auf die Thematik.  </p>
				<p>Klicken Sie abschließend auf Speichern.  </p>
				<p>Ihre Antworten werden der zuständigen Person zurückgespielt</p>
			</v-alert>
			<p class="pa-3 goal-title ig-header">{{goal.name}}</p>
			<p>{{goalGroup.name}}
			<br/>{{department.name}}</p>

			<Questions 
			:questions="questions"
			:error="false"
			:data="data"
			@update="updateValue"
			></Questions>
			<p class="text-right">

				<v-dialog v-model="dialog" :width="mwstylesettings.SmallDialogWidth">
					<template v-slot:activator="{on}">
						<v-btn v-on="on" large color="primary" class="mr-2">{{mwtr.SaveButton}}</v-btn>
					</template>
					<v-card>
						<v-card-title></v-card-title>
						<v-card-text>
							{{mwtr.PollReturnMessage}}
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="save" color="primary">{{mwtr.SaveButton}}</v-btn>
						</v-card-actions>
					</v-card>				
				</v-dialog>
			</p>
			<Saved :trigger="saved" @saved="s => saved = s"></Saved>
		</template>
		<div v-else>
			<p v-if="saved">
				{{mwtr.PollThankYou }}
			</p>
			<v-alert v-else color="secondary">
				{{mwtr.AccessDenied }}
			</v-alert>
			
		</div>
	</v-sheet>
</template>

<style lang="less">
	.goal-title {
		font-weight: 400;
		font-size: 1.4em;
		margin-left: -15px;
	}
</style>

<script type="text/javascript">
	import Vue from "vue";
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	import Questions from "@/components/survey/render/Questions"
	export default {
		name: "Render", 
		props: {
			id: {}
		},
		data: () => {
			return {
				dialog: false,
				saved: false,
				data: {},
			}
		},
		components: {
			Questions, 
			Saved, 
		},
		computed: {
			...Vuex.mapState({
				poll(state){
					var id = this.id; 
					return state.collaborations[id] || false;
				},
				goal(state){
					var id = this.poll.goal; 
					return state.goals[id] || false;
				},
				goalGroup(state){
					var id = this.poll.goalGroup; 
					return state.goalGroups[id] || false;
				},
				department(state){
					var id = this.poll.department; 
					return state.departments[id] || false;
				}, 
			}), 	
			accessGranted(){
				if( !this.poll ){
					return false;
				}
				if( ['submitted', 'deleted'].indexOf(this.poll.status) > -1){
					return false;
				}
				return true
			},
			questions(){
				return this.$store.getters.questionArray;
			}
		},
		methods: {
			updateValue(id, value ){
				if( typeof value !== undefined ){
					Vue.set(this.data, id, value)
				}
			}, 
			save(){
				var data = {
					data: this.data,
					id: this.id,
				}
				this.$store.dispatch("collaborations/savePoll", data).then(() => {
					this.dialog = false;
					this.saved = true;
				}).catch(( ) => {
					this.error = true;
				})
			}, 
		},
		watch: {
			id: {
				immediate: true, 
				handler(){
					this.$store.dispatch("collaborations/fetchPoll", this.id)
				}
			}
		},
		created(){
			this.$store.dispatch("questions/fetchAll");
		}
	}
// </script>"